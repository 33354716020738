@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@500&family=League+Spartan:wght@400;700&display=swap');

*,
*::before,
*::after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    font-family: "Antonio", "League Spartan", sans-serif;
    font-size: 0.875rem;
    color: white;
}
