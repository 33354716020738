@media (min-width: 10rem) {

    .burger_btn{
        display: block;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        background-color: var(--bg-color);
        height: 5.3125rem;
        padding: 0rem 2rem;
        border-bottom: 1px solid #97979783;
        
    }
    
    .logo{
        text-transform: uppercase;
        font-size: 1.75rem;
    }

    .mobile_color_circle_mercury,
    .mobile_color_circle_venus,
    .mobile_color_circle_earth,
    .mobile_color_circle_mars,
    .mobile_color_circle_jupiter,
    .mobile_color_circle_saturn,
    .mobile_color_circle_uranus,
    .mobile_color_circle_neptune{
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        margin: 1rem 2rem;
    }

    .mobile_color_circle_mercury{
        background-color: #DEF4FC;
    }
    .mobile_color_circle_venus{
        background-color: #F7CC7F;
    }
    .mobile_color_circle_earth{
        background-color: #545BFE;
    }
    .mobile_color_circle_mars{
        background-color: #FF6A45;
    }
    .mobile_color_circle_jupiter{
        background-color: #ECAD7A;
    }
    .mobile_color_circle_saturn{
        background-color: #FCCB6B;
    }
    .mobile_color_circle_uranus{
        background-color: #65F0D5;
    }
    .mobile_color_circle_neptune{
        background-color: #497EFA;
    }

    .nav_list_active{
        padding: 2.75rem 0rem;
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        width: 41.5625rem;
        list-style: none;
        height: 110vh;
        width: 100vw;
        background-color: var(--bg-color);
        top: 5.3125rem;
        right: 0%;
        transition: ease-in-out 0.5s;
        z-index: 5;
    }

    .nav_list_inactive{
        padding: 2.75rem 0rem;
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        width: 41.5625rem;
        list-style: none;
        height: 110vh;
        width: 100vw;
        background-color: var(--bg-color);
        top: 5.3125rem;
        right: 100%;
        transition: ease-in-out 0.5s;
        z-index: 5;
    }

    .nav_item{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .mobile_nav_menu_line{
        border: 0.1px solid #97979783;
        width: 100vw;
    }

    .chevron{
        position: absolute;
        right: 2rem;
    }
    
    .nav_link{
        text-decoration: none;
        color: #cacaca;
        text-transform: uppercase;
        font-size: 0.9375rem;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "League Spartan";
        transition: ease-in-out 0.2s;
    }
    
    .nav_link:hover{
        color: var(--white);
        transition: ease-in-out 0.2s;
    }

}

@media (min-width: 43.75rem) {

    .burger_btn{
        display: none;
    }

    .nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        background-color: var(--bg-color);
        height: 10rem;
        padding: 0rem 2rem;
    }
    
    .logo{
        text-transform: uppercase;
        font-size: 1.75rem;
        margin-top: 1rem;
    }
    
    .nav_list_inactive{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 41.5625rem;
        list-style: none;
    }

    .mobile_color_circle_mercury,
    .mobile_color_circle_venus,
    .mobile_color_circle_earth,
    .mobile_color_circle_mars,
    .mobile_color_circle_jupiter,
    .mobile_color_circle_saturn,
    .mobile_color_circle_uranus,
    .mobile_color_circle_neptune{
        display: none;
    }

    .chevron{
        display: none;
    }

    .mobile_nav_menu_line{
        display: none;
    }

    .nav_list_inactive{
        position: static;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 41.5625rem;
        list-style: none;
        height: 1.5625rem;
        width: 41.5625rem;
        background-color: transparent;
        top: 2.5rem;
        right: 0%;

    }
    
    .nav_link{
        text-decoration: none;
        color: #cacaca;
        text-transform: uppercase;
        font-size: 0.6875rem;
        letter-spacing: 1px;
        font-family: "League Spartan";
        transition: ease-in-out 0.2s;
    }
    
    .nav_link:hover{
        color: var(--white);
        transition: ease-in-out 0.2s;
    }
}

@media (min-width: 57.8125rem) {

    .burger_btn{
        display: none;
    }

    .nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        background-color: var(--bg-color);
        height: 5.3125rem;
        padding: 0rem 2rem;
    }
    
    .logo{
        text-transform: uppercase;
        font-size: 1.75rem;
    }
    
    .nav_list_inactive{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 41.5625rem;
        list-style: none;
        background-color: transparent;
    }
    
    .nav_link{
        text-decoration: none;
        color: #cacaca;
        text-transform: uppercase;
        font-size: 0.6875rem;
        letter-spacing: 1px;
        font-family: "League Spartan";
        transition: ease-in-out 0.2s;
    }
    
    .nav_link:hover{
        color: var(--white);
        transition: ease-in-out 0.2s;
    }
    
}