:root{
    --white: #FFFFFF;
    --bg-color: #070724;
    --bg-hover: #38384F;
    --very-light-gray: #838391;
    --very-light-blue: #419EBB;
    --yellow: #EDA249;
    --purple: #6f2ed6;
    --amber: #D14C32;
    --pinkish-red: #D83A34;
    --brown: #CD5120;
    --cyan: #1ec2a4;
    --light-blue: #2d68f0;
}