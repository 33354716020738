@media (min-width: 20rem) {

    .planet_facts_desktop{
        display: none;
    }

    .mercury_structure_container{
        background-color: var(--bg-color);
        background-image: url(../../Assets//background-stars.svg);
    }

    .pagination_structure{
        display: none;
    }

    .pagination_mobile_structure{
        position: relative;
        width: 100vw;
        background-color: var(--bg-color);
        height: 3.125rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0rem 2rem;
        list-style: none;
        z-index: 1;
        border-bottom: 1px solid #97979783;
    }

    .overview_btn_mobile_structure_mercury,
    .structure_btn_mobile_structure_mercury,
    .surface_btn_mobile_structure_mercury{
        width: 5rem;
        background-color: transparent;
        border: none;
        height: 3.125rem;
        color: #97979783;
        font-family: "League Spartan", sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1.93px;
        font-size: 0.6rem;
        cursor: pointer;
    }

    .structure_btn_mobile_structure_mercury{
        border-bottom: 5px solid var(--very-light-blue);
        padding-top: 5px;
        color: white;
    }

    .structure_link_to_overview,
    .structure_link_to_surface{
        color: white;
        text-decoration: none;
    }

    .mercury_structure{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 100vw;
    }

    .planet_img_container{
        padding: 6.125rem 0rem;
    }

    .planet_img{
        width: 6.9375rem;
        height: auto;
    }

    .mercury_structure_text_container,
    .mercury_structure_text_wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        height: 11rem;
        width: calc(100vw - 3rem);
    }

    .mercury_title{
        text-transform: uppercase;
        font-weight: 400;
        font-size: 2.5rem;
    }

    .mercury_structure_description{
        text-align: center;
        font-family: "League Spartan", sans-serif;
        line-height: 1.375rem;
    }

    .planet_facts{
        height: 13.5rem;
        margin-top: 2.75rem;
        width: calc(100vw - 3rem);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 5rem;
    }

    .mercury_rotation,
    .mercury_revolution,
    .mercury_radius,
    .mercury_temp{
        padding: 0px 1.5rem;
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #97979783;
    }

    .mercury_rotation_title,
    .mercury_revolution_title,
    .mercury_radius_title,
    .mercury_temp_title{
        font-family: "League Spartan", sans-serif;
        font-size: 0.5rem;
        text-transform: uppercase;
        color: #97979783;
        letter-spacing: 0.0456rem;
    }

    .mercury_rotation_time,
    .mercury_revolution_time,
    .mercury_radius_num,
    .mercury_temp_num{
        font-weight: 400;
        font-size: 1.25rem;
        text-transform: uppercase;
    }

}

@media (min-width: 43.75rem) {

    .planet_facts_desktop{
        display: none;
    }

    .planet_img{
        width: 11.5rem;
        height: auto;
    }

    .pagination{
        display: block;
    }

    .pagination_mobile_structure{
        display: none;
    }

    .mercury_structure_text_container{
        flex-direction: row;
    }

    .mercury_structure_text_wrapper{
        width: 21.1875rem;
        height: 12.25rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    }

    .mercury_structure_description{
        text-align: left;
    }

    .pagination_structure{
        height: 9.5rem;
        width: 17.5625rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        list-style: none;
    }

    .pagination_item_structure{
        width: 100%;
        height: 2.5rem;
    }

    .overview_btn_structure_mercury,
    .structure_btn_structure_mercury,
    .surface_btn_structure_mercury{
        cursor: pointer;
        width: 100%;
        height: 100%;
        border: 1px solid #97979783;
        background-color: transparent;
        font-family: "League Spartan";
        font-size: 0.5625rem;
        font-weight: bold;
        letter-spacing: 1.93px;
        text-transform: uppercase;
        color: white;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0rem 1rem;
    }

    .pagination_num_structure{
        color: #97979783;
        margin-right: 1rem;
    }

    .structure_btn_structure_mercury{
        background-color: var(--very-light-blue);
    }

    .structure_link_to_overview,
    .structure_link_to_surface{
        text-decoration: none;
        color: white;
    }

    .planet_facts{
        height: 5.5rem;
        margin-top: 2.75rem;
        width: calc(100vw - 3rem);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
        margin-bottom: 5rem;
    }

    .mercury_rotation,
    .mercury_revolution,
    .mercury_radius,
    .mercury_temp{
        padding: 1.5rem;
        width: 10.25rem;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: 1px solid #97979783;
        flex-direction: column;
    }

    .mercury_rotation_title,
    .mercury_revolution_title,
    .mercury_radius_title,
    .mercury_temp_title{
        font-family: "League Spartan", sans-serif;
        font-size: 0.5rem;
        text-transform: uppercase;
        color: #97979783;
        letter-spacing: 0.0456rem;
    }

    .mercury_rotation_time,
    .mercury_revolution_time,
    .mercury_radius_num,
    .mercury_temp_num{
        font-weight: 400;
        font-size: 1.25rem;
        text-transform: uppercase;
    }

}

@media (min-width: 57.8125rem) {

    .mercury_structure_container{
        height: 130vh;
    }

    .planet_img{
        width: 18.125rem;
        height: auto;
    }

    .mercury_structure{
        flex-direction: row;
        width: 58.75rem;
        margin-inline: auto;
    }

    .mercury_structure_text_container{
        flex-direction: column;
        width: 21.875rem;
        height: 28rem;
        width: 21.875rem;
    }

    .mercury_structure_text_container{
        flex-direction: column;
        width: 21.875rem;
        align-items: flex-start;
    }

    .mercury_structure_text_wrapper{
        flex-direction: column;
        width: 21.875rem;
        height: 14.3125rem;
    }

    .mercury_title{
        font-size: 5rem;
    }

    .pagination{
        width: 100%;
    }

    .planet_facts{
        display: none;
    }

    .planet_facts_desktop{
        display: block;
        height: 5.5rem;
        margin-top: 3.5rem;
        width: 58.75rem;
        margin-inline: auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
        margin-bottom: 5rem;
    }

}